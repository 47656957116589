let ReturnRequest = {
    filesToBeUploaded: [],

    init: function () {
        this.bind();
    },

    bind: function () {
        this.form();
        this.uploads();
    },

    form() {
        $(document).on('change', '#purchase_place', () => {
            this.toggleFields();
        });
        $(document).on('change', '#product', () => {
            this.toggleFields();
        });
    },

    toggleFields() {
        let purchasePlace = $('#purchase_place option:selected').val();
        let product = $('#product option:selected').val();

        if (purchasePlace === 'Amazon') {
            $('#amazon_order_number').closest('.form-group').show();
        } else {
            $('#amazon_order_number').closest('.form-group').hide();
        }

        if (product === 'LevelMatePRO' || product === 'LevelMatePRO OEM') {
            $('#purchase_place').closest('.form-group').hide();
            $('#amazon_order_number').closest('.form-group').hide();
        } else {
            $('#purchase_place').closest('.form-group').show();
            $('#amazon_order_number').closest('.form-group').show();
        }
    },

    uploads() {
        let _this = this;

        $(document).on('change', '#file', function () {
            if (this.files.length > 0) {
                for (let i = 0; i < this.files.length; i++) {
                    _this.filesToBeUploaded.push(this.files.item(i));
                }

                // _this.filesToBeUploaded.push(this.files);

                _this.redrawUploadsPreview();
            }
        });

        $(document).on('click', '.remove-file', function () {
            const id = $(this).data('id');
            _this.filesToBeUploaded.splice(id, 1);
            _this.redrawUploadsPreview();
        });

        $(document).on('submit', '#returnRequestForm', function (e) {
            e.preventDefault();
            let data = new FormData();
            let storedFiles = _this.filesToBeUploaded;

            for (let i = 0, len = storedFiles.length; i < len; i++) {
                data.append('files[]', storedFiles[i]);
            }
            data.append('name', $('#name').val());
            data.append('email', $('#email').val());
            data.append('address', $('#address').val());
            data.append('city', $('#city').val());
            data.append('state', $('#state').val());
            data.append('post_code', $('#post_code').val());
            data.append('phone', $('#phone').val());
            data.append('product', $('#product').val());
            data.append('purchased_at', $('#purchased_at').val());
            data.append('purchase_place', $('#purchase_place').val());
            data.append('amazon_order_number', $('#amazon_order_number').val());
            data.append('request_type', $('#request_type').val());
            data.append('reason', $('#reason').val());
            data.append('return_replacement_code', $('#return_replacement_code').val());
            data.append('country', $('#country').val());

            $.ajax({
                url: '/return-request',
                type: 'post',
                data: data,
                contentType: false,
                processData: false,
                success: response => {
                    if (response.success) {
                        toastr.success(response.message);
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                    } else {
                        toastr.error(response.message);
                        _this.resetSubmitButtons();
                    }
                },
                error: (err) => {
                    const error = err.responseJSON;
                    if (error.errors) {
                        toastr.error(Object.values(error.errors).join('<br>')).css("width", "500px");
                    } else {
                        toastr.error(error.message);
                    }
                    _this.resetSubmitButtons();
                }
            });

            /*$.post('/return-request', {
                name: 'name',
                email: 'asd@asd.cc',
                files: _this.filesToBeUploaded
            }, response => {
                console.log(response);
            });*/

            /*let xhr = new XMLHttpRequest();
            xhr.open('POST', '/return-request', false);
            let token = document.head.querySelector('meta[name="csrf-token"]');
            data.append('_token', token.content);

            xhr.onload = function(e) {
                if(this.status == 200) {
                    console.log(e.currentTarget.responseText);
                    alert(e.currentTarget.responseText);
                }
            }

            xhr.send(data);*/
        });
    },

    redrawUploadsPreview() {
        let _this = this;

        $('#files').val(_this.filesToBeUploaded);

        console.log(_this.filesToBeUploaded);
        const previewContainer = $('#uploadsPreview');
        previewContainer.empty();
        /*const rowTemplate = '<div class="row upload">\n' +
            // '<div class="col-2 icon">:icon</div>\n' +
            '<div class="col-11 name">:name</div>\n' +
            '<div class="col-1 remove"><a href="javascript:void(0)" class="remove-file" data-id=":id"><i class="fa fa-trash text-danger"></i></a></div>\n' +
            '</div>';*/
        const rowTemplate = '<div class="col-4 upload mt-2">\n' +
                '<div class="background" style="background-image: url(:url);">\n' +
                '<div class="name">:name</div>\n' +
                '<div class="remove-file" data-id=":id"><i class="fa fa-times text-danger"></i></div>\n' +
                '</div>\n' +
            '</div>';


        _this.filesToBeUploaded.forEach((file, index) => {
            let type = file.type;
            let url = type === 'application/pdf' ? '/img/frontend/pdf.png' : URL.createObjectURL(file);
            let row = rowTemplate.replace(':name', file.name + ' (' + _this.calculateSize(file.size) + ')').replace(':id', index.toString()).replace(':url', url);
            previewContainer.append(row);
        });
    },

    calculateSize(size) {
        let suffix = 'KB';

        size = size / 1024;
        if (size >= 1024) {
            size = size / 1024;
            suffix = 'MB';
        }

        if (size >= 1024) {
            size = size / 1024;
            suffix = 'GB';
        }

        return size.toFixed(2).toString() + suffix
    },

    resetSubmitButtons() {
        $('button[type=submit]').prop('disabled', false);
    }
};


$().ready(() => {
    window.ReturnRequest = ReturnRequest;
    window.ReturnRequest.init();
});
