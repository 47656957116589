let Init = {
    init: function () {
        this.bind();
    },

    bind: function () {
        this.datepicker();
        this.toastr();
    },

    datepicker() {
        $('.datepicker').datepicker({
            format: 'mm/dd/yyyy',
            orientation: 'bottom',
            autoclose: true
        });
    },

    toastr() {
        toastr.options = {
            "debug": false,
            "newestOnTop": false,
            "positionClass": "toast-top-right",
            "closeButton": true,
            "toastClass": "animated fadeInDown",
        };
    },
};


$().ready(() => {
    window.Init = Init;
    window.Init.init();
});
